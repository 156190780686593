import { gql } from 'apollo-boost';
import { USER_FRAGMENT, AUTH0USER_FRAGMENT } from './fragments';

export const USER_QUERY = gql`
  query USER_QUERY($id: UserId) {
    user(id: $id) {
      ...User
      accessTo {
        user {
          ...Auth0User
        }
        oldPortalAccess
      }
      accessToMe {
        user {
          ...Auth0User
        }
        oldPortalAccess
      }
    }
  }
  ${USER_FRAGMENT}
  ${AUTH0USER_FRAGMENT}
`;

export const AGENCY_USERS_QUERY = gql`
  query AGENCY_USERS_QUERY {
    agency {
      users {
        totalCount
        results {
          ...User
          accessTo {
            user {
              ...Auth0User
            }
            oldPortalAccess
          }
          accessToMe {
            user {
              ...Auth0User
            }
            oldPortalAccess
          }
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${AUTH0USER_FRAGMENT}
`;

export const OLD_PORTAL_REDIRECT_QUERY = gql`
  query OLD_PORTAL_REDIRECT_QUERY($id: BrokerId!, $requestedPage: String!) {
    oldPortalRedirect(id: $id, requestedPage: $requestedPage) {
      location
    }
  }
`;

export const MEDICARE_ADVANTAGE_APPLICATIONS_QUERY = gql`
  query MEDICARE_ADVANTAGE_APPLICATIONS_QUERY(
    $id: BrokerId
    $skip: Int
    $first: Int
    $orderBy: [String]
  ) {
    medicareApplications(
      id: $id
      skip: $skip
      first: $first
      orderBy: $orderBy
    ) {
      totalCount
      results {
        brokerId
        brokerName
        applicationSteps {
          eventDate
          id
          sourceSystem
          stage
        }
        appReceivedDate
        dateOfBirth
        id
        name
        stepPercentage
        subscriberId
      }
    }
  }
`;

export const MEDICARE_SUPPLEMENT_APPLICATIONS_QUERY = gql`
  query MEDICARE_SUPPLEMENT_APPLICATIONS_QUERY(
    $id: BrokerId
    $skip: Int
    $first: Int
    $orderBy: [String]
  ) {
    medSuppApplications(
      id: $id
      skip: $skip
      first: $first
      orderBy: $orderBy
    ) {
      totalCount
      results {
        brokerId
        brokerName
        applicationSteps {
          eventDate
          id
          sourceSystem
          stage
        }
        appReceivedDate
        dateOfBirth
        id
        name
        stepPercentage
        subscriberId
      }
    }
  }
`;

export const INDIVIDUAL_APPLICATIONS_QUERY = gql`
  query INDIVIDUAL_APPLICATIONS_QUERY(
    $id: BrokerId
    $skip: Int
    $first: Int
    $orderBy: [String]
  ) {
    individualApplications(
      id: $id
      skip: $skip
      first: $first
      orderBy: $orderBy
    ) {
      totalCount
      results {
        brokerId
        brokerName
        applicationSteps {
          eventDate
          id
          sourceSystem
          stage
        }
        applicationId
        effectiveDate
        selectedPlan
        appReceivedDate
        dateOfBirth
        id
        name
        stepPercentage
        subscriberId
      }
    }
  }
`;

export const SPLIT_QUERY = gql`
  query SPLIT_QUERY($name: Split!) {
    split(name: $name) {
      name
      enabled
      treatment
    }
  }
`;

export const MEDICARE_BOB_QUERY = gql`
  query MEDICARE_BOB_QUERY(
    $id: BrokerId
    $skip: Int
    $first: Int
    $orderBy: [String]
  ) {
    maClosedBook(id: $id, skip: $skip, first: $first, orderBy: $orderBy) {
      first
      skip
      totalCount
      results {
        brokerId
        brokerName
        dateOfBirth
        dental
        email
        familyName
        givenName
        id
        medical
        name
        phoneNumber
        products {
          description
          name
          type
        }
        subscriberId
      }
    }
  }
`;

export const INDIVIDUAL_BOB_QUERY = gql`
  query INDIVIDUAL_BOB_QUERY(
    $id: BrokerId
    $skip: Int
    $first: Int
    $orderBy: [String]
  ) {
    individualClosedBook(
      id: $id
      skip: $skip
      first: $first
      orderBy: $orderBy
    ) {
      first
      skip
      totalCount
      results {
        brokerId
        brokerName
        dateOfBirth
        dental
        effectiveDate
        email
        id
        medical
        memberCount
        name
        phoneNumber
        premium
        primaryCareProvider
        reformStatus
        renewalMonth
        subscriberId
        subsidy
      }
    }
  }
`;

export const GROUP_BOB_QUERY = gql`
  query GROUP_BOB_QUERY(
    $id: BrokerId
    $skip: Int
    $first: Int
    $orderBy: [String]
  ) {
    groupClosedBook(id: $id, skip: $skip, first: $first, orderBy: $orderBy) {
      first
      skip
      totalCount
      results {
        brokerId
        brokerName
        effectiveDate
        hasDental
        hasMedical
        hasVision
        id
        name
        contractCount
        memberCount
      }
    }
  }
`;

export const COMMISSIONS_QUERY = gql`
  query COMMISSIONS_QUERY($skip: Int, $first: Int, $months: Int) {
    commissions(skip: $skip, first: $first, months: $months) {
      totalCount
      first
      skip
      results {
        title
        dateCreated
        dateOfRecord
        id
        reportType
        fileName
      }
    }
  }
`;

export const DRTS_QUERY = gql`
  query DRTS_QUERY($skip: Int, $first: Int, $selectedBrokerId: String, $orderBy: [String], $groupFilter: String, $month: Int, $year: Int) {
    drts(skip: $skip, first: $first, selectedBrokerId: $selectedBrokerId, orderBy: $orderBy, groupFilter: $groupFilter, month: $month, year: $year) {
      totalCount
		  first
		  skip
		  results {
			  groupName
        groupId
			  fileName
			  description
			  id
        runDate
			  __typename
		  }
      groups {
        groupName
        groupId
      }
		  __typename
    }
  }
`;

export const GROUP_RENEWALS_QUERY = gql`
  query GROUP_RENEWALS_QUERY($skip: Int, $first: Int, $agencyId: String, $orderBy: [String], $groupFilter: String, $brokerFilter: String, $fileNameFilter: String, $month: Int, $year: Int) {
    groupRenewals(skip: $skip, first: $first, agencyId: $agencyId, orderBy: $orderBy, groupFilter: $groupFilter, brokerFilter: $brokerFilter, fileNameFilter: $fileNameFilter, month: $month, year: $year) {
      totalCount
		  first
		  skip
		  results {
			  id
        brokerId
        groupName
        groupId
			  fileName
			  filePath
			  generatedDate
        effectiveDate
        __typename
		  }
      filters {
        filterType
        filterList {
          filterKey
          filterValue
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const INDIVIDUAL_DELINQUENCY_QUERY = gql`
  query INDIVIDUAL_DELINQUENCY_QUERY($brokerId: String, $skip: Int, $first: Int, $orderBy: [String], $filter: String) {
    memberDelinquency(brokerId: $brokerId, skip: $skip, first: $first, orderBy: $orderBy, filter: $filter) {
      results {
        amountDue
        aptcStatus
        brokerId
        brokerName
        delinquentDate
        groupId
        onExchange
        paidThroughDate
        planDescription
        receiptAmount
        receiptDate
        subscriberFirstName
        subscriberId
        subscriberLastName
        subscriberMI
        subscriberName
        __typename
      }
      first
      skip
      totalCount
        __typename
    }
  }
`;

export const INDIVIDUAL_PAIDTODATESTATUS_QUERY = gql`
  query INDIVIDUAL_PAIDTODATESTATUS_QUERY($brokerId: String, $search: String) {
    memberPtdStatus(brokerId: $brokerId, search: $search) {
      results {
        aPTCStatus
        automaticBankDraft
        automaticBankDraftDate
        billingLastDueDate
        brokerId
        currentBalance
        effectiveDate
        firstName
        lastName
        lastPaymentAmount
        lastPaymentDate
        memberKey
        memberRelation
        monthlyPremium
        onExchange
        paidThroughDate
        programId
        programName
        status
        subscriberId
        subscriberKey
        subscriberName
        subsidyAmount
        termDate
        __typename
      }
        __typename
    }
  }
`;

export const INDIVIDUAL_TERMINATION_QUERY = gql`
  query INDIVIDUAL_TERMINATION_QUERY($brokerId: String, $year: Int, $month: Int, $skip: Int, $first: Int, $orderBy: [String], $filter: String) {
    memberTermination(brokerId: $brokerId, year: $year, month: $month, skip: $skip, first: $first, orderBy: $orderBy, filter: $filter) {
      results {
        enrolleeAddress1
        enrolleeAddress2
        enrolleeAddress3
        enrolleeCity
        enrolleeEmail
        enrolleeFirstName
        enrolleeLastName
        enrolleeName
        enrolleeNumber
        enrolleePhone
        enrolleeState
        enrolleeZip
        reason
        reasonDescription
        termDate
        __typename
      }
      first
      skip
      totalCount
        __typename
    }
  }
`;